<template>
  <div class="container-fluid multiple-tabs mt-3">
    <Form @submit="updateArticle" v-if="!loading" v-slot="{ errors }">
      <div class="d-flex justify-content-between align-items-center flex-wrap">
        <Breadcrumb :parent_page="{title: 'المقالات' , path: '/articles'}" current_page="تعديل المقال"/>
        <div class="controls-btns d-flex gap-2 mb-4">
          <button class="btn btn-publish" type="submit" @click="updateArticle" :disabled="loading">حفظ</button>
          <button class="btn btn-save-draft">حفظ مسودة</button>
        </div>
      </div>

      <el-card v-if="!loading">
        <el-tabs v-model="activeTab">
          <el-tab-pane label="المقال" name="article">
            <ArticleDetails :details="data" @update="handleOnChange" :contentError="contentError" :errors="errors"/>
          </el-tab-pane>
          <el-tab-pane label="محركات البحث" name="seo">
            <SEO :seo-details="data" @update="handleOnChange"/>
          </el-tab-pane>
          <el-tab-pane label="منصات التواصل الاجتماعي" name="social-media">
            <SocialMedia :social-data="data" @update="handleOnChange"/>
          </el-tab-pane>
        </el-tabs>
      </el-card>

      <Loader v-else/>

    </Form>
  </div>
</template>

<script>
import SocialMedia from "@/views/articles/components/socialMedia";
import SEO from "@/views/articles/components/SEO";
import ArticleDetails from "@/views/articles/components/articleDetails";
import Breadcrumb from "@/components/Breadcrumb";
import {Form} from "vee-validate";
import {getSingleArticle, updateArticle} from "@/services/articles";
import Loader from "@/components/Loader";

export default {
  name: "Edit_Article",
  components: {
    Loader,
    SocialMedia,
    SEO,
    ArticleDetails,
    Breadcrumb,
    Form
  },
  data()
  {
    return {
      activeTab: 'article',
      data: {},
      contentError : false,
      loading: false
    }
  },
  mounted() {
    this.fetchArticleData()
  },
  methods: {
    handleOnChange(key, val)
    {
      this.data = {
        ...this.data,
        [key]: val
      }
    },
    fetchArticleData(){
      this.loading = true
      getSingleArticle(this.$route.params.article_id)
      .then(res=>{
        this.loading = false
        res.data.seo_name = res.data.slug
        res.data.seo_title = res.data.metas.seo_title
        res.data.seo_description = res.data.metas.seo_description
        res.data.social_title = res.data.metas.social_title
        res.data.social_description = res.data.metas.social_description
        res.data.social_image = res.data.metas.social_image
        this.data = res.data
        console.log(res)
      })
      .catch(()=> {
        this.loading = false
      })
    },
    updateArticle(){
      updateArticle(this.$route.params.article_id,this.data)
      .then(res=>{
        setTimeout(()=> this.$router.push('/articles'),300)
        this.toast.success("تم تعديل الخبر بنجاح", {
          position: "top-center",
        });
        console.log(res)
      })
      .catch(e=>{
        console.log(e)
      })

    }
  }
}
</script>


<style lang="scss">
.btn-save {
  background-color: #7367F0;
}

.btn-publish {
  background-color: #09c05b;
}

.btn-save-draft {
  background-color: #7367F0;
}

.btn-preview {
  background-color: #0dbfd4;
}

.controls-btns .btn {
  color: #fff;
  min-width: 100px;


  &:hover {
    opacity: 0.9;
    box-shadow: 0 3px 3px rgba(0, 0, 0, .16);
  }
}


.el-tabs__nav {
  float: right;
}

</style>

